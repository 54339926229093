/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from "antd";
import { FormInput } from "Components";
import React, { useEffect, useMemo, useState } from "react";
import {
  DateTimeTitle,
  EndCol,
  MidleDateTime,
  ModalComponentWrapper,
  StartCol,
} from "./styled";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TableComponent from "Components/Table";
import _ from "lodash";
import NormalButton from "Components/Button/NormalBtn";
import { useHistory } from "react-router-dom";
import actions from "Store/Actions";
import { useDispatch, useSelector } from "react-redux";
import { FailureBtn } from "Components/Button";
import ListCourseChecked from "../ListCourseChecked";
import { IS_EXPORT_STEP1, RESPONSE_STATUS } from "Constants";
import { exportSchedule } from "Utils/export-csv";
import { useTranslation } from "react-i18next";
import { transformMaxScoreValue } from "Utils/object-transform";
import LoadingModal from "Pages/LoadingModal";
import { convertDataContentBackFirst, convertLableCharacter, convertObjectValues, sortObjectByOrder } from "Utils/convertValue";
import ModalConfirm from "../ModalConfirm";

const validationSchema = yup.object({}).required();
const {
  confirmedInformation,
  getScheduleCourse,
  addNewClassSchedule,
} = actions;


const ModalInforStudent = ({
  visibleModal,
  onOk,
  onCancel,
  dataIndividualExam,
  dataGeneralExam,
  inforStudent,
  selectedContent,
  selectedContentPerson,
  aspirationId,
  dataCharacter,
  weeklyCourse,
  ratioTimeunitSubject,
  optionTimeLimit
}) => {
  const [t] = useTranslation('student');
  const dataRatioTimeunitSubject = {...ratioTimeunitSubject} 
  for (let key in dataRatioTimeunitSubject) {
    if (dataRatioTimeunitSubject.hasOwnProperty(key)) {
      if (dataRatioTimeunitSubject[key] === "") {
        dataRatioTimeunitSubject[key] = 0;
      } else {
        dataRatioTimeunitSubject[key] = parseInt(dataRatioTimeunitSubject[key]);
      }
    }
  }
  const form = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, setValue } = form;
  const history = useHistory()
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataSelectCharater, setDataSelectCharater] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [dataConfirm, setDataConfirm] = useState(false);
  useEffect(() => {
    if (!_.isEmpty(dataCharacter)) {
      const changeLable= convertLableCharacter(dataCharacter)
      const sortData = sortObjectByOrder(changeLable)
      const objConvertText = convertObjectValues(sortData)
      const objData = {objDt : objConvertText}
      const dt = transformMaxScoreValue(objData)
      setDataSelectCharater(dt)
    }
  },[dataCharacter])
  const {
    loadingScheduleCourse,
    aspirationalID,
    currentStudentInformation,
    listSubjectContentCommonFirst,
    listSubjectContentPersonalFirst
  } = useSelector((state) => state.student);
  
  useEffect(() => {
    if (!_.isEmpty(inforStudent)) {
      for (const [key, value] of Object.entries(inforStudent)) {
        setValue(key, value);
      }
    }
  }, [
    inforStudent.scheduleMethodNew,
    inforStudent.studentID,
    inforStudent.startTime,
    inforStudent.endTime,
    inforStudent.socialScience,
    inforStudent.facultyNameNew,
    inforStudent.universityNameNew,
    inforStudent.majorNameNew,
    inforStudent.branchAspiration,
  ]);
  const selectContentConvert = useMemo(() => {
    return convertDataContentBackFirst(selectedContent,listSubjectContentCommonFirst)
  }, [selectedContent])
  const selectedContentPersonConvert = useMemo(() => {
    return convertDataContentBackFirst(selectedContentPerson,listSubjectContentPersonalFirst)
  },[selectedContentPerson])
  const onSubmit = async (data) => {
    if (currentStudentInformation?.universityNameNew && currentStudentInformation.hasOwnProperty('dataCharacter')) {
      setDataConfirm(data)
      setConfirm(true);
    }
    else {
      callApiGen(data)
    }
  };
  const callApiGen = (data) => {
    setLoading(true)
      const { 
        studentID, 
        startTime, 
        endTime,
        scheduleMethod,
        scheduleMethodNew,
        universityNameNew,
        facultyNameNew,
        majorNameNew,
        studentName,
        socialScience,
        branchAspiration,
        studentClass,
      } = data;
      let obj = {
        studentID,
        startTime,
        endTime,
        socialScience,
        selectedContent: {
          "共通テスト": selectContentConvert,
          "二次・個別": selectedContentPersonConvert,
        },
        aspirationID: aspirationId,
        character: dataCharacter,
        ratioTimeunitSubject: optionTimeLimit ==='time' ? dataRatioTimeunitSubject : {},
        studentClass,
        limitPeriod : Number(weeklyCourse)
      };
      dispatch(getScheduleCourse(obj,  (action, res) => {
        if(res.status === RESPONSE_STATUS.SUCCESS){
          let coursesID = []
          res.data?.course?.map(item => 
            item[`番号`] && coursesID.push(item[`番号`])
          )
          if(IS_EXPORT_STEP1){
            dispatch(addNewClassSchedule({
              studentID, 
              startTime, 
              endTime, 
              coursesID, 
              scheduleMethod,
              aspirationSelected: aspirationalID, 
              teacherComment: ""
            },  async(action, response) => {
              if (response.status === RESPONSE_STATUS.SUCCESS) {
                await exportSchedule({generatedClassScheduleID: response.data.generatedClassScheduleID, studentID}, studentName);
              }
            }))
          }else{
            history.push({
              // pathname: `/result-lesson/${studentID}/create`,
              pathname: `/student/${studentID}/curriculum/create/lesson-results`,
              state: { socialScience: inforStudent.socialScience ,  dataCharacter : dataCharacter, branchAspiration: inforStudent.branchAspiration ? inforStudent.branchAspiration : "***", aspirationID: aspirationId },
            })
          }
          setLoading(false)
        dispatch(confirmedInformation({
          studentID,
          startTime,
          endTime,
          scheduleMethod: scheduleMethodNew,
          universityName: universityNameNew,
          departmentName: facultyNameNew,
          majorName: majorNameNew,
          branchAspiration,
          socialScience,
          studentName, 
          selectedContent: {
            "共通テスト": selectContentConvert,
            "二次・個別": selectedContentPersonConvert,
          },
          scheduleMethodNew,
          universityNameNew,
          facultyNameNew,
          majorNameNew,
          dataCharacter,  
          ratioTimeunitSubject: optionTimeLimit ==='time' ? dataRatioTimeunitSubject : {},
          optionTimeLimit:optionTimeLimit,
          limitPeriod: Number(weeklyCourse),
          backupData: true,
          sortSubject: false,
        }))
        }
        setLoading(false)
      }))
  }
  const handleConfirmGen = () => {
    callApiGen(dataConfirm)
  }
  const handleCancelGen = () => {
    setLoading(true)
    const { 
      studentID, 
      startTime, 
      endTime,
      scheduleMethodNew,
      universityNameNew,
      facultyNameNew,
      majorNameNew,
      studentName,
      socialScience,
      branchAspiration,
    } = dataConfirm;
    dispatch(confirmedInformation({
      studentID,
      startTime,
      endTime,
      scheduleMethod: scheduleMethodNew,
      universityName: universityNameNew,
      departmentName: facultyNameNew,
      majorName: majorNameNew,
      branchAspiration,
      socialScience,
      studentName, 
      selectedContent: {
        "共通テスト": selectedContent,
        "二次・個別": selectedContentPersonConvert,
      },
      scheduleMethodNew,
      universityNameNew,
      facultyNameNew,
      majorNameNew,
      dataCharacter,
      ratioTimeunitSubject: optionTimeLimit === 'time' ? dataRatioTimeunitSubject : {},
      optionTimeLimit,
      limitPeriod:Number(weeklyCourse),
      backupData: true,
      sortSubject: true,
    }))
    history.push({
      // pathname: `/result-lesson/${studentID}/create`,
      pathname: `/student/${studentID}/curriculum/create/lesson-results`,
      state: { socialScience: inforStudent.socialScience ,  dataCharacter : dataCharacter, branchAspiration: inforStudent.branchAspiration ? inforStudent.branchAspiration : "***", aspirationID: aspirationId },
    })
    setLoading(false)
  }
  return (
    <>
      <ModalComponentWrapper
        open={visibleModal}
        title={"生徒情報確認"}
        onOk={onOk}
        onCancel={onCancel}
        className='modal-infor-student'
      >
        <FormProvider {...form}>
          <form id="confirm-infomation-form" method="post" autoComplete="off">
            <Row gutter={[32]}>
              <Col span={24} lg={12}>
                <FormInput
                  label={t("studentID")}
                  name="studentID"
                  rules=""
                  disabled={true}
                />
              </Col>
              <Col span={24} lg={12}>
                <FormInput
                  label={t("student_name")}
                  name="studentName"
                  rules=""
                  disabled={true}
                />
              </Col>
            </Row>

            <Row gutter={[32]}>
              <Col span={24} lg={12}>
                <FormInput
                  label={t("university_name")}
                  name="universityNameNew"
                  rules=""
                  disabled={true}
                />
              </Col>
              <Col span={24} lg={12}>
                <FormInput
                  label={t("faculty_name")}
                  name="facultyNameNew"
                  rules=""
                  disabled={true}
                />
              </Col>
            </Row>

            <Row gutter={[32]}>
              <Col span={24} lg={12}>
                <FormInput
                  label={t("department_major")}
                  name="majorNameNew"
                  rules=""
                  disabled={true}
                />
              </Col>
              <Col span={24} lg={12}>
                <FormInput
                  label={t("schedule_method")}
                  name="scheduleMethodNew"
                  rules=""
                  disabled={true}
                />
              </Col>
            </Row>
            <Row gutter={[32]} justify="end">
              <Col span={24} lg={12}>
                <FormInput label={t("social_science")} name="socialScience" disabled={true} defaultValue={inforStudent.socialScience}/>
              </Col>
              <Col span={24} lg={12}>
                <DateTimeTitle span={24}>
                  <span>{t("course_period")}</span>
                </DateTimeTitle>
                <Row gutter={[32]}>
                  <Col span={11}>
                    <FormInput
                      label=""
                      name="startTime"
                      rules=""
                      disabled={true}
                    />
                  </Col>
                  <MidleDateTime span={2}>~</MidleDateTime>
                  <Col span={11}>
                    <FormInput label="" name="endTime" rules="" disabled={true} />
                  </Col>
                </Row>
              </Col>
            </Row>
            {
              inforStudent.branchAspiration === undefined || inforStudent.branchAspiration === null ? (
                <></>
              ) : (
              <Row gutter={[32]}>
                <Col span={24} lg={12}>
                  <FormInput
                    label={t("branch_name")}
                    name="branchAspiration"
                    rules=""
                    disabled={true}
                  />
                </Col>
              </Row>
              )
            }
            <TableComponent
              columns={dataSelectCharater?.objDt?.nameCourse}
              dataSource={dataSelectCharater?.objDt?.valueCourse}
              titleTable={t("aptitude_test_tb")}
            />

            <TableComponent
              columns={dataGeneralExam?.nameCourse}
              dataSource={dataGeneralExam?.valueCourse}
              titleTable={t("common_test")}
            />

            <TableComponent
              columns={dataIndividualExam?.nameCourse}
              dataSource={dataIndividualExam?.valueCourse}
              titleTable={t("secondary_individual")}
            />

            <ListCourseChecked dataSource={selectedContent} dataselectedContentPerson={selectedContentPerson}/>

          </form>
        </FormProvider>
        <Row justify={"center"} gutter={[32]}>
          <EndCol span={12} lg={12}>
            <NormalButton onClick={onCancel}>{t("cancel")}</NormalButton>
          </EndCol>
          <StartCol span={12} xl={12}>
            <FailureBtn onClick={handleSubmit(onSubmit)} loading={loadingScheduleCourse || loading} disabled={loadingScheduleCourse ||loading}>{t("confirmation")} </FailureBtn>
          </StartCol>
        </Row>
      </ModalComponentWrapper>
      <ModalConfirm
        onConfirm={handleConfirmGen}
        onCancelGen={handleCancelGen}
        visibleModal={confirm}
        onCancelIcon={()=>setConfirm(false)}
      />
      {loading && <LoadingModal textLoading={t("generating_scheduled_courses")}/>}
    </>
  );
};

export default ModalInforStudent;
