import React, { useState, useEffect } from "react";
import {
  ModalComponentWrapper,
  WrapperLabel,
  EndCol,
  StartCol,
} from "./styled";
import { Col, Row, Input, Radio } from "antd";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { FailureBtn } from "Components/Button";
import NormalButton from "Components/Button/NormalBtn";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Notification } from "Components";

i18next.loadNamespaces(["student"]);

const ModalWeeklyCourse = ({
  onOk,
  onCancel,
  visibleModal,
  listSubject,
  weeklyCourseMax,
}) => {
  const [t] = useTranslation("student");
  const [loading, setLoading] = useState(false);
  const [optionTimeLimit, setOptionTimeLimit] = useState("time");
  const { currentStudentInformation } = useSelector((state) => state.student);
  console.log('listSubject',listSubject)
  const initialSubjectValues = {};
  listSubject.forEach((subject) => {
    initialSubjectValues[subject] = 1;
  });
  const [subjectValues, setSubjectValues] = useState(initialSubjectValues);

  const handleInputChange = (subject, value) => {
    if (value > Number(weeklyCourseMax)) {
      setSubjectValues((prevState) => ({
        ...prevState,
        [subject]: Number(weeklyCourseMax),
      }));
    } else {
      setSubjectValues((prevState) => ({
        ...prevState,
        [subject]: value ? Number(value) : value,
      }));
    }
  };

  useEffect(() => {
    if (
      !_.isEmpty(currentStudentInformation) &&
      currentStudentInformation.hasOwnProperty("ratioTimeunitSubject")
    ) {
      const intersection = _.intersection(
        listSubject,
        Object.keys(currentStudentInformation.ratioTimeunitSubject)
      );
      setOptionTimeLimit(currentStudentInformation.optionTimeLimit)
      if (
        intersection.length === listSubject.length &&
        intersection.length ===
          Object.keys(currentStudentInformation.ratioTimeunitSubject).length && currentStudentInformation.ratioTimeunitSubject 
      ) {
        setSubjectValues(currentStudentInformation.ratioTimeunitSubject);
      } else {
        setSubjectValues(initialSubjectValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStudentInformation]);

  const onSubmit = () => {
    setLoading(true);

    const sumReduce = Object.values(subjectValues).reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue),
      0
    );
    setLoading(false);
    if (Number(sumReduce) !== Number(weeklyCourseMax) && optionTimeLimit ==='time') {
      Notification.error(t("error_subject_allocation_designation"));
    } else {
      onOk(subjectValues,optionTimeLimit);
    }
  };

  const setAllValues = () => {
    // setSubjectValues initialSubjectValues
  };
  const onCancelModal = () => {
    setAllValues();
    onCancel();
  };
  const onChange = (e) => {
    setOptionTimeLimit(e.target.value);
  };
  const handleKeyDown = (e) => {
    const keyCode = e.keyCode || e.which;
    if (!/^\d$/.test(e.key) && keyCode !== 8 && keyCode !== 46) {
      e.preventDefault();
    }
  };
  return (
    <ModalComponentWrapper
      open={visibleModal}
      title={t("subject_allocation_designation")}
      onOk={onOk}
      onCancel={onCancelModal}
      centered
    >
      <Row gutter={[32]}>
        <Col span={24}>
          <div className="list_option_timeLimit">
            <Radio.Group
              className="option_timeLimit"
              onChange={onChange}
              value={optionTimeLimit}
            >
              <Radio className="option_one" value={"notime"}>
              時限指定なし
              </Radio>
              <Radio value={"time"}>時限指定</Radio>
            </Radio.Group>
          </div>
      </Col>
      </Row>
      {optionTimeLimit === 'time' &&
        <Row gutter={[32]}>
          {listSubject.map((subject, index) => (
            <Col span={24} className="value-input-number">
              <WrapperLabel>{subject}</WrapperLabel>
              <Input
                type="number"
                min={0}
                max={weeklyCourseMax}
                onKeyDown={handleKeyDown}
                value={subjectValues[subject]}
                onChange={(event) =>
                  handleInputChange(subject, event.target.value)
                }
              />
            </Col>
          ))}
        </Row>}
      <Row justify={"center"} gutter={[32]} className="footer_btn">
        <EndCol span={12} lg={12}>
          <NormalButton onClick={onCancelModal}>{t("cancel")}</NormalButton>
        </EndCol>
        <StartCol span={12} xl={12}>
          <FailureBtn onClick={onSubmit} loading={loading} disabled={loading}>
            {t("confirm")}{" "}
          </FailureBtn>
        </StartCol>
      </Row>
    </ModalComponentWrapper>
  );
};

export default ModalWeeklyCourse;
