import ModalComponent from 'Components/Modal'
import styled from 'styled-components'
import { Col } from 'antd';

export const ModalComponentWrapper= styled(ModalComponent)`
  width: 500px !important;
  .value-input-number{
    margin-bottom: 0px;
  }
  .list_option_timeLimit{
    .option_timeLimit{
      margin-top: 10px;
      display: flex;
      justify-content: center;
      gap: 16px;
    }
  }
  .ant-modal-body {
    .ant-row {
      margin-top: 10px;
    }
    .footer_btn {
      margin-top: 40px;
    }
  }

  .ant-form-item {
    height: max-content;
    width: 100%;
    margin-bottom: 10px;

    .ant-select {
      min-height: 40px !important; 
      border-radius: 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      .ant-select-selector{
        min-height: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000 !important;

        .ant-select-selection-search-input {
          height: 100%;
        }

        .ant-select-selection-item{
          display: flex;
          align-items: center;
        }
      }
    }

    .ant-form-item-row{
      display: flex;
      flex-direction: column;
    }

    .ant-form-item-label{
      text-align: start;
    }

    .ant-select-disabled{
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000 !important;
    }

    .select__menu {
      z-index: 10;
    }

    .select__control {
      border: ${({ validateStatus, theme }) =>
        validateStatus === 'error'
          ? `1px solid ${theme.error_ant} !important`
          : ''};
      box-shadow: ${({ validateStatus }) =>
        validateStatus === 'error' ? 'none' : ''};
    }

    .ant-form-item-children-icon {
      display: none;
    }

    .select__dropdown-indicator {
      color: hsl(0, 0%, 60%);
    }

    label::after{
      content: "" !important;
    }
  }

  .ant-form-item-control-input {
    .ant-input-number, .ant-picker {
      width: 100%;
      min-height: 40px !important; 
      border-radius: 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      .ant-input-number-input-wrap {

        input {
          min-height: 40px;
        }
      }
    }
  }
  .ant-input {
    min-height: 40px !important;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* :-webkit-autofill {
		background-color: white !important;
		color: #000000 !important;
		} */
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
    -webkit-text-fill-color: #686e7c;
    -moz-text-fill-color: #686e7c;
    -o-text-fill-color: #686e7c;
    -ms-text-fill-color: #686e7c;
  }
  input:focus-visible {
    outline: none;
  }

  .ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item-label {
    text-align: start;
  }

  .ant-input-disabled {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000 !important;
  }

  .input-group {
    flex-wrap: nowrap !important;
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: transparent !important;
  }

  .ant-input-affix-wrapper-status-error .ant-input-prefix {
    color: rgba(0, 0, 0, 0.85);
  }


  .ant-form-item-label {
    font-size: 1.16rem;
    overflow: unset;
    white-space: unset;
    .ant-form-item-no-colon {
      height: 100%;
    }
  }
`

export const WrapperLabel = styled.div`
	width: 100%;
	font-weight: 500;
	font-size: 1.3rem;
	line-height: 30px;
	color: #6D737A;
  .icon-question{
    margin-left: 10px;
  }
`;

export const HeaderModal = styled(Col)`
  display: flex; 
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid #D9D9D9;

  @media only screen and (max-width: 768px ) {
   margin-bottom: 25px;
  }

  .header_modal {
    display: grid;
  }

  .info_title{
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .info_data{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

`

export const GroupButton = styled(Col)`
  display: flex;
  justify-content: flex-end;

   @media only screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;

    button{
      margin-top: 1rem;
      padding: 0 14px;
      width: 45px;
    }
  }

`
export const EndCol= styled(Col)`
  display: flex;
  justify-content: flex-end;
`
export const StartCol= styled(Col)`
  display: flex;
  justify-content: flex-start;
`